import Vue from "vue"; // 使用 Vue 2 的方式
import VueI18n from "vue-i18n"; // 引入 VueI18n
import en from "./locales/en.json"; // 載入英文翻譯文件
import zh from "./locales/zh.json"; // 載入中文翻譯文件

// 安裝 VueI18n 插件
Vue.use(VueI18n);

// 創建 VueI18n 實例
const i18n = new VueI18n({
    locale: "en", // 設定預設語言
    fallbackLocale: "en", // 當翻譯不存在時回退至英文
    messages: {
        en, // 英文翻譯內容
        zh, // 中文翻譯內容
    },
});

export default i18n;